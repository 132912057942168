import {
  listGroupsWithRoleForUser,
  listRegistrations,
} from '../../../DataStore/Services';
import {
  CourseRegistrationStatus,
} from '../../../../models/index';

const listUserGroups = async (sub) => {
  const userWithRoles = await listGroupsWithRoleForUser(sub);
  if (userWithRoles) {
    console.log('userWithRoles', userWithRoles);
  }
};

const fetchRegisteredCourses = async (status) => {
  const courses = await listRegistrations();
  let courseList;
  if (courses) {
    courseList = courses.filter(
      (course) => {
        if (status) {
          return course.status === status;
        }
        return course.status !== CourseRegistrationStatus.REQUESTED;
      },
    );
  }
  return courseList;
};
export {
  listUserGroups,
  fetchRegisteredCourses,
};
