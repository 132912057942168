import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  listUsersInGroups,
} from '../../DataStore/Services';

dayjs.extend(isBetween);

/**
 * filter by days selected
 * @param {*} data
 * @param {*} days
 */
const filterByDate = (data, dateRange) => {
  let filteredData = [];
  if (!(dayjs(dateRange?.endDate).isSame(dayjs(dateRange?.startDate)))) {
    filteredData = data.filter(
      (course) => {
        const splitDate = course.createdAt.split('/');
        const formattedDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
        return (
          dayjs(formattedDate).isSame(dateRange.startDate, 'date')
      || dayjs(formattedDate).isSame(dateRange.endDate, 'date')
      || dayjs(formattedDate)
        .isBetween(dayjs(dateRange.startDate).toISOString(),
          dayjs(dateRange.endDate).toISOString())
        );
      },
    );
  } else {
    filteredData = data;
  }
  const sortedData = filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return sortedData;
};

/**
 * Filter by selected groups
 * @param {*} data
 * @param {*} assignedCourses
 * @param {*} groupList
 * @param {*} selectedGroupNames
 */
const filterByGroup = async (data, groupList, selectedGroupNames) => {
  let filteredData = [];
  if (selectedGroupNames.length !== 0) {
    const groupIds = [];

    for (let i = 0; i < groupList.length; i += 1) {
      if (selectedGroupNames.includes(groupList[i].name)) {
        groupIds.push(groupList[i].id);
      }
    }
    // collect userId's from groupMember
    const usersInGroups = await listUsersInGroups(groupIds);
    if (usersInGroups) {
      const currentDataList = [...data];
      // filter against current assigned coures list
      for (let i = 0; i < currentDataList.length; i += 1) {
        const currentData = currentDataList[i];
        for (let j = 0; j < usersInGroups.length; j += 1) {
          const groupMembers = usersInGroups[j];
          if (groupMembers?.member?.sub === (currentData?.sub || currentData?.user?.sub)) {
            filteredData.push(currentData);
          }
        }
      }
    }
  } else {
    filteredData = []; // check
  }
  return filteredData;
};
export {
  filterByDate,
  filterByGroup,
};
// export {
//   filterByDate,
// };
