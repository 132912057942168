import { updateCourseRegistration } from '../../GraphQL/Services';
import {
  CourseRegistrationStatus,
} from '../../../models/index';
import { deleteRegisteredCourse } from '../../DataStore/Services';
import { toaster } from '../../../services/utils';
/**
   * aprrove requested course
   *
   * @param {*} record
   */
const approveRequest = async (record, bulkApprove = false) => {
  const updateCourseReg = await updateCourseRegistration(
    record?.id, record?.courseID, record?._version, CourseRegistrationStatus.APPROVED,
    /* eslint no-underscore-dangle: 0 */
  );
  if (updateCourseReg) {
    if (bulkApprove) {
      return updateCourseReg;
    }
    toaster('Course approved');
  }
  return null;
};
  /**
   * soft delete requested course
   *
   * @param {*} record
   */
const deleteCourse = async (record) => {
  const courseDeleteResponse = await deleteRegisteredCourse(record?.id);
  if (courseDeleteResponse) {
    toaster('Course Access Denied', 'success', 2);
  }
};
const bulkApproveHandler = async (selectedRows) => {
  const promises = selectedRows.map((row) => approveRequest(row, true));
  await Promise.all(promises).then((response) => {
    console.log(response);
    toaster('Courses approved', 'success', 1);
  }).catch((err) => {
    console.log(err);
    toaster('Not all courses approved', 'success', 1);
  });
};
export {
  approveRequest,
  deleteCourse,
  bulkApproveHandler,
};
